import React from "react"
import styles from "./article-pages.module.css"
import {Link} from "gatsby";
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import LinkButton from "../buttons/link-button/link-button";
import Background from "../../../static/articles/dizain/bg.jpg";

const Dizain = () => {

    return (
        <ModalRoutingContext.Consumer>
            {({ modal, closeTo }) => (
                <div className={modal ? styles.articleModal : styles.articlePage}>
                    {modal ? (
                        <Link className={"close-modal"} to={"/#articles"}>&times;</Link>
                    ) : "" }
                    <div className={styles.articleBackground} style={{
                        backgroundImage: `url(${Background})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                    }}>
                        <div className={styles.imageBackground}>
                            <p className={styles.backgroundText}>
                                10 трендов в дизайне интерьера
                            </p>
                        </div>
                    </div>
                    <div className={styles.content}>

                        <p>Популярные
                            тренды в дизайне интерьера быстро сменяют друг друга в рамках года, сезона и
                            даже месяца. Некоторые из них не остаются даже в памяти. Но есть, так
                            называемая, классика, которая останется навсегда.</p>

                        <p>1. Индивидуальность и эксклюзивность. Особенный дизайн, индивидуальные пространства,
                            созданные с учётом всех пожеланий заказчика, мебель, созданная для конкретного проекта.
                            Этот тренд набирает обороты последнее время и останется с нами надолго, потому что в
                            мире массового потребления – индивидуальный дизайн становится роскошью. </p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/dizain/1.jpg"/>
                        </div>

                        <p>2. Экологичность и использование
                            природных материалов. Ресурсы не безграничны, а желание человека быть ближе к природе
                            будет существовать всегда. Поэтому использование в интерьере природных материалов, таких
                            как дерево, лён, шерсть, растения всегда будет актуальным и любимым трендом. Именно за
                            это в мире так любят скандинавский дизайн. </p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/dizain/2.jpeg"/>
                        </div>

                        <p>3. Технологии на службе дизайна и интерьера. Новые технологии создадут много возможностей
                            и смогут сделать нашу жизнь более комфортной. 3D печать, «умный дом», гаджеты,
                            современные материалы. С каждым годом их применение в интерьерах и предметном дизайне
                            будет только возрастать. </p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/dizain/3.jpg"/>
                        </div>

                        <p>4. Уютные интерьеры с мягкими, фактурными материалами и сдержанными цветами. Долговечный
                            тренд на светлые нейтральные интерьеры – это дань тому, что живём мы в стремительно
                            меняющемся и непостоянном мире. Поэтому дома нам хочется найти спокойствие и уют.
                            Нейтральные бежевые и даже розовые оттенки любимы заказчиками. Теперь они могут честно
                            занять своё место среди модных и востребованных тенденций. </p>


                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/dizain/4.jpg"/>
                        </div>

                        <p>5. Универсальные, трансформируемые пространства со множеством функций. С ростом числа
                            жителей больших городов и уменьшением размеров квартир, актуальными становятся небольшие
                            жилые пространства, которые могут трансформироваться и быть в разное время дня гостиной,
                            спальней и рабочим кабинетом, например. Такие квартиры уже очень востребованы в странах
                            Юго-Восточной Азии. И эта тенденция стремительно охватывает Россию и западные
                            страны.
                            <div className={styles.imageWrapperCentered}>
                                <img src="/articles/dizain/5.jpg"/>
                            </div>
                        </p>


                        <p>6. Технологичные кухни-гостиные. Кухня становится всё менее изолированной и плавно
                            перетекающей в жилое пространство. Тренд на профессиональное оборудование на домашней
                            кухне – это оправдано и удобно. Быстро и без потери витаминов, одним нажатием кнопки
                            приготовить эксклюзивное блюдо, как в лучшем ресторане и пригласить компанию друзей
                            готовить вместе и вместе наслаждаться вкусами. </p>

                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/dizain/7.jpeg"/>
                        </div>

                        <p>7. Расслабляющие ванные комнаты с элементами СПА. Это также история про отдых и
                            восстановление, в котором так нуждается современный человек. Новые материалы,
                            современное оборудование: новые модели
                                смесителей, лейки с тропическим душем, мини-бассейны с массажем. Выбор есть на
                            любой вкус и бюджет, и каждый может сделать свою ванную комнату удобной и красивой. И
                            это тоже надолго, потому что востребовано и положительно влияет на здоровье. </p>


                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/dizain/8.jpg"/>
                        </div>

                        <p>8. Свет как самостоятельный материал. Наблюдается тенденция к усложнению роли освещения в
                            нашей жизни. С помощью света можно менять цвет стен, настроение в помещении и влиять на
                            здоровье и работоспособность. Светильники, подстраивающиеся под биоритмы человека и
                            автоматически меняющие температуру света в зависимости от времени суток. Это не будущее,
                            это уже работает. В дальнейшем роль света в дизайне не потеряет свои позиции, спрос
                            будет увеличиваться. </p>


                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/dizain/9.jpg"/>
                        </div>

                        <p>9. Соединение традиций и инноваций. Речь идет о традиционных ремёслах и древних техниках,
                            которые объединяют с новыми необычными материалами. Например, плетёная мебель,
                            изготовленная из пластиковых верёвок, которые в свою очередь сделаны из отходов. </p>


                        <div className={styles.imageWrapperCentered}>
                            <img src="/articles/dizain/10.jpg"/>
                        </div>


                        <p>10. Осознанное потребление – логичный и полезный тренд. Когда мы экономим ресурсы и не
                            спешим выбрасывать старую мебель, а бережно относимся, реставрируем или перекрашиваем и
                            получаем что-то новое и необычное. Так, например, один известный бренд делает обои из
                            старых индийских сари. Получаются очень необычные фактурные обои, которые используют в
                            самых эксклюзивных и модных интерьерах. Это очень экологичный и спасительный для нашего
                            мира тренд, он уже опробован временем и останется навсегда.

                            <div className={styles.imageWrapperCentered}>
                                <img src="/articles/dizain/11.jpg"/>
                            </div>
                        </p>

                        <div style={{textAlign: 'center', marginTop: '40px'}}>
                            <LinkButton text={'Вернуться на сайт'} path={modal ? "/#articles" : ""}/>
                        </div>
                    </div>
                </div>
            )}
        </ModalRoutingContext.Consumer>
    );
};

export default Dizain
