import React from "react"
import Container from "../components/container/container";
import SEO from "../components/seo";
import YM from "../components/analytics/ym";
import Dizain from "../components/articles/10-trendov-dizaina";

const DizainPage = () => (
    <Container>
        <SEO
            title="complexstroy.by | Дизайны ванных комнат 2020"
            description="Дизайны ванных комнат 2020"/>
        <YM />
        <Dizain />
    </Container>
);

export default DizainPage
